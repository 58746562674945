/**************************/
/* BELOW 1920×1080 */
/**************************/
@media (max-width: 160em) {
  .footer {
    height: 15rem;
  }

  .copyright {
    margin-bottom: 15rem;
  }

  .page-three .page-text {
    width: 60%;
  }

  .page-three .logo-container {
    height: 40rem;
  }

  .page-three .logo-text {
    /* font-size */
    font-size: 10.2rem;
  }

  .page-two .text h3,
  .page-three .text-container h3 {
    /* font-size */
    font-size: 3.6rem;
  }

  .page-three .text-container p {
    padding-bottom: 2%;
    /* font-size */
    font-size: 2.4rem;
  }
}

/**************************/
/* BELOW 1536×864 */
/**************************/
@media (max-width: 96em) {
  .footer {
    height: 13rem;
  }

  .copyright {
    margin-bottom: 13rem;
  }

  .page-one .big-ideas p {
    /* fonsize */
    font-size: 7rem;
  }

  .page-one .logo-container p {
    /* fonsize */
    font-size: 2.6rem;
  }

  .page-three .page-text {
    width: 75%;
    padding-bottom: 4%;
  }

  .page-three .logo-container {
    height: 28rem;
  }

  .page-three .logo-text {
    /* font-size */
    font-size: 8.1rem;
  }

  .page-two .text h3,
  .page-three .text-container h3 {
    /* font-size */
    font-size: 2.8rem;
  }

  .page-three .text-container p {
    padding-bottom: 2%;
    /* font-size */
    font-size: 1.9rem;
  }

  .form-text {
    font-size: 2.1rem;
  }

  .protective-padding {
    padding-bottom: 5%;
  }
}

/**************************/
/* BELOW 1366×768 */
/**************************/
@media (max-width: 85.375em) {
  .footer {
    height: 11rem;
  }

  .copyright {
    margin-bottom: 11rem;
  }

  .page-two .text {
    width: 90%;
  }

  .page-three .logo-container {
    height: 28rem;
  }
  .page-three .logo-text {
    /* font-size */
    font-size: 8.2rem;
  }
  .page-three .page-text {
    width: 85%;
    padding-bottom: 2%;
  }

  .page-two .text h3,
  .page-three .text-container h3 {
    /* font-size */
    font-size: 2.8rem;
  }
}

/**************************/
/* BELOW 1280×720*/
/**************************/
@media (max-width: 80em) {
  body {
    font-size: 1.8rem;
  }

  .logo-stripe {
    height: 4.8rem;
  }

  .navigation-img {
    width: 22rem;
  }

  .form ::placeholder {
    /* font-size */
    font-size: 1.8rem;
  }

  .page-one .big-ideas {
    gap: 12%;
  }

  .page-one .big-ideas p {
    /* fonsize */
    font-size: 6.4rem;
  }

  .page-one .logo-container p {
    /* fonsize */
    font-size: 2.4rem;
  }

  .page-one .statement {
    /* fontsize */
    font-size: 2.8rem;
  }

  .page-one .list {
    /* fontsize */
    font-size: 2.1rem;
  }

  .page-one .end-statement {
    /* fontsize */
    font-size: 2.1rem;
  }

  .page-two .title {
    font-size: 5.2rem !important;
  }

  .page-two .blue-logo {
    width: 21rem;
  }

  .page-two .text h3,
  .page-three .text-container h3 {
    /* font-size */
    font-size: 2.1rem !important;
  }

  .page-two .text p {
    /* font-size */
    font-size: 1.8rem;
  }

  .page-three .logo-container {
    height: 25rem;
  }
  .page-three .logo-text {
    /* font-size */
    font-size: 7.2rem;
  }

  .page-three .page-text {
    width: 80%;
    padding-bottom: 0;
  }

  .page-three .text-container p {
    /* font-size */
    font-size: 1.8rem;
  }

  .form-text {
    /* font-size */
    font-size: 1.9rem;
  }

  .protective-padding {
    padding-bottom: 5%;
  }
}

/**************************/
/* BELOW 763×1024*/
/**************************/
@media (max-width: 68em) {
  body {
    line-height: 1.3;
  }

  .navigation {
    height: 15%;
    position: relative;
  }

  .navigation-list {
    position: absolute;
    top: 100%;
    border-top: none;
    width: 100%;
    background-color: #102640;
    flex-direction: column;
    justify-content: center;
    padding: 1%;
    z-index: -10;
    /* fontsize */
    font-size: 2.1rem;
  }

  .navigation-img {
    display: none;
  }

  .navigation-img.phone {
    display: inline-block;
    width: 25rem;
  }

  .nav-line {
    display: none;
  }

  .ph-list {
    display: flex;
    align-items: center;
    padding: 1rem;
    font-size: 2.4rem;
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 5;
  }

  .slide-menu {
    transform: translateY(-75%);
    opacity: 0;
    overflow: hidden;
    background-color: #102640;
    transition: 0.3s all ease;
  }

  .slide-menu.open {
    opacity: 1;
    z-index: 10;
    transform: translateY(0);
  }

  .footer {
    position: absolute;
    background-color: rgb(16, 38, 64, 0.6);
    bottom: 7.5%;
    left: 50%;
    transform: translate(-50%, 7.5%);
    width: 75%;
    z-index: 2;
  }

  .copyright {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.4rem;
    text-align: center;
    color: #102640;
    position: relative;
    background-color: #d9dde4;
    padding: 2% 0;
  }

  .form {
    width: 90%;
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
    padding-bottom: 10%;
  }

  .form-text {
    background-color: #10264099;
    width: 100%;
  }

  .form-text p {
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 0.9;
    padding: 1rem 0;
    font-size: 2.1rem;
    width: 100%;
    grid-column: span 4;
    margin: 0 auto;
  }

  .btn-subscribe {
    grid-column: span 2;
    width: 50%;
    margin: 0 auto;
    background-color: #d21e46;
    font-size: 1.8rem;
    color: #fff;
    border: none;
    padding: 0.4rem;
  }

  .form-text hr {
    display: none;
  }

  .page-one .logo-container {
    display: none;
  }

  .page-one .big-ideas .logo-stripe {
    left: 35%;
  }

  .page-one .details-ideas .paragraph {
    width: 100%;
    line-height: 1.15;
  }

  .page-one .details-ideas {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding-top: 3%;
  }

  .page-one .list {
    display: grid;
    grid-template-columns: min-content min-content;
    justify-items: center;
    line-height: 1.11;
  }

  .page-one .list-item {
    gap: 2%;
  }
  .page-one .list-item:last-child {
    grid-column: span 2;
  }

  .page-one .list span {
    padding-top: 0.75%;
  }

  .page-one .end-statement {
    /* white-space: nowrap; */
    width: 100%;
    text-align: center;
  }

  .page-one .statement {
    grid-template-columns: 1fr;
  }

  .page-one .statement p {
    margin: 0 auto;
    text-align: center;
  }

  .page-one .paragraph {
    width: 90% !important;
  }

  .page-one .big-ideas p {
    text-align: center;
  }

  .page-one .statement hr {
    display: none;
  }

  .page-two .text {
    width: 100%;
    padding: 3% 5%;
  }

  .page-two .text h2 {
    padding-top: 1%;
    flex-direction: column;
    padding-bottom: 3%;
  }

  .page-two .text {
    width: 90%;
  }

  .page-two .blue-logo {
    width: 25rem;
  }

  .page-two .part {
    padding-bottom: 5%;
  }

  .page-two .second-part {
    padding-bottom: 3%;
  }

  .page-two .text h3,
  .page-three .text-container h3 {
    /* font-size */
    font-size: 2.4rem !important;
    padding-bottom: 1%;
  }

  .page-two .text p {
    /* font-size */
    font-size: 1.8rem;
  }

  .btn-link-website {
    width: 100%;
    white-space: nowrap;
    margin: 2% auto 5% auto;
    padding: 1.4rem 1.4rem;
    /* fontsize */
    font-size: 1.6rem;
  }

  .page-three .page-text {
    flex-direction: column;
    width: 75%;
    text-align: center;
  }

  .page-three .part-title {
    display: none;
  }

  .page-three .text-container h3 {
    padding-top: 3%;
  }

  .page-three .text-container h3:last-child {
    padding-top: 0%;
  }

  .padding-top {
    padding-top: 0%;
  }

  .page-three .logo-stripe {
    display: none;
  }

  .page-container .copyright-div {
    padding: 5% 10% 5% 10%;
  }
}

/**************************/
/* BELOW 412×915*/
/**************************/
@media (max-width: 37.5em) {
  body {
    font-size: 1.6rem;
  }

  .logo-stripe {
    height: 4.4rem;
    display: none;
  }

  .navigation {
    height: 12%;
  }

  .navigation-list {
    font-size: 1.6rem;
  }

  .navigation-img.phone {
    width: 16rem;
  }

  .page-one {
    background-image: none;
  }

  .page-one::before {
    content: "";
    position: absolute;
    top: 8%;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../img/phone1.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .page-one .page-text {
    top: 20%;
    transform: translate(0%, -20%);
  }

  .page-one .big-ideas .logo-stripe {
    left: 15%;
    top: -62%;
    height: 20rem;
    display: inline-block;
  }

  .page-one .big-ideas p {
    font-size: 5.4rem;
  }

  .page-one .statement {
    padding: 6% 0 0 0;
    font-size: 2.1rem;
  }

  .page-one .list {
    font-size: 1.6rem;
  }

  .page-one .list-item p,
  .page-one .end-statement {
    font-weight: 500 !important;
  }

  .page-one .list span {
    width: 0.8rem;
    height: 0.8rem;
    margin-top: 1%;
  }

  .page-one .end-statement {
    font-size: 1.6rem;
    width: 80%;
    margin: 0 auto;
    line-height: 0.9;
  }

  .footer {
    width: 95%;
    bottom: 2%;
  }

  .form input {
    padding: 0.5rem;
    font-size: 1.6rem;
  }

  .form-text p {
    flex-direction: row;
    gap: 2%;
    width: fit-content;
    margin: 0 auto;
    padding: 1rem 0;
    font-size: 1.6rem;
    line-height: 0.9;
  }

  .btn-subscribe {
    font-weight: 400 !important;
    font-size: 1.6rem;
  }

  .page-two {
    background-image: none;
  }

  .page-two::before {
    content: "";
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../img/phone2.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 1;
  }

  .page-two .page-text {
    position: relative;
    z-index: 2;
    width: 85%;
    height: 90%;
  }

  .page-two .title {
    font-size: 3.8rem !important;
  }

  .page-two .blue-logo {
    width: 16rem;
  }

  .page-two .text {
    padding: 3% 2%;
  }

  .page-two .text h3,
  .page-three .text-container h3 {
    padding-bottom: 1%;
    font-size: 1.8rem !important;
  }

  .page-two .text p {
    font-size: 1.6rem;
  }

  .page-two .text {
    width: 95%;
  }

  .page-two .page-text {
    padding: 0%;
  }

  .page-two .second-part {
    padding-bottom: 1%;
  }

  .btn-link-website {
    margin: 2% auto 5%;
    padding: 0.8rem 0.8rem;
    font-size: 1.4rem;
    white-space: wrap;
  }

  .page-three {
    overflow: visible;
  }

  .page-three .page-text {
    width: 85%;
    height: 100%;
    top: 10%;
    transform: translate(0%, 10%);
  }

  .page-three .text-container {
    background-color: #d9dde4;
    padding: 6% 0%;
    padding-bottom: 1%;
  }

  .page-three .logo-container {
    height: 18rem;
  }

  .page-three .logo-text {
    font-size: 5.2rem;
  }

  .page-three .text-container p {
    font-size: 1.6rem;
  }

  .copyright {
    display: none;
  }

  .copyright-phone {
    display: flex;
    flex-direction: column;
    padding-top: 15%;
  }

  .copyright-phone p {
    font-size: 1.3rem !important;
  }

  .page-container .copyright-div {
    padding: 8% 10% 25% 10%;
  }

  .page-container .copyright-div h2 {
    font-size: 1.8rem;
  }
  .page-container .copyright-div h3 {
    font-size: 1.6rem;
    text-align: center;
  }

  .page-container .copyright-div p {
    font-size: 1.6rem;
    text-align: center;
  }

  .page-terms .copyright-terms {
    padding: 2% 0.5%;
    flex-direction: column;
    width: 100%;
    text-align: center;
    gap: 0.8rem;
    font-size: 1.4rem;
  }

  .section-accessibility .accessibility-icon {
    font-size: 3.8rem;
  }

  .section-accessibility .accessibility-slide {
    transform: translateX(-12rem);
    width: 12rem;
    padding: 3% 5%;
  }

  .section-accessibility h3 {
    font-size: 1.6rem;
  }

  .section-accessibility .list {
    gap: 0.8rem;
    font-size: 1.5rem;
  }
}

/**************************/
/* BELOW 360×800*/
/**************************/
@media (max-width: 22.5em) {
}

/***********************************/
/* BELOW 390 width and 700 height*/
/***********************************/
@media (max-width: 24.375em), (max-height: 43.75em) {
  .form {
    gap: 0.8rem;
    width: 90%;
    padding-bottom: 10%;
  }

  .page-one .big-ideas .logo-stripe {
    height: 18rem;
    top: -62%;
    left: 15%;
  }

  .page-one .big-ideas p {
    font-size: 4.8rem;
  }

  .page-two .text h2 {
    flex-direction: row;
    gap: 2%;
  }

  .page-two .blue-logo {
    width: 14rem;
  }
  .page-two .title {
    font-size: 3.2rem !important;
  }

  .page-three .logo-container {
    height: 16rem;
  }

  .page-three .logo-text {
    font-size: 4.8rem;
  }
}
