@media (width <= 160em) {
  .footer {
    height: 15rem;
  }

  .copyright {
    margin-bottom: 15rem;
  }

  .page-three .page-text {
    width: 60%;
  }

  .page-three .logo-container {
    height: 40rem;
  }

  .page-three .logo-text {
    font-size: 10.2rem;
  }

  .page-two .text h3, .page-three .text-container h3 {
    font-size: 3.6rem;
  }

  .page-three .text-container p {
    padding-bottom: 2%;
    font-size: 2.4rem;
  }
}

@media (width <= 96em) {
  .footer {
    height: 13rem;
  }

  .copyright {
    margin-bottom: 13rem;
  }

  .page-one .big-ideas p {
    font-size: 7rem;
  }

  .page-one .logo-container p {
    font-size: 2.6rem;
  }

  .page-three .page-text {
    width: 75%;
    padding-bottom: 4%;
  }

  .page-three .logo-container {
    height: 28rem;
  }

  .page-three .logo-text {
    font-size: 8.1rem;
  }

  .page-two .text h3, .page-three .text-container h3 {
    font-size: 2.8rem;
  }

  .page-three .text-container p {
    padding-bottom: 2%;
    font-size: 1.9rem;
  }

  .form-text {
    font-size: 2.1rem;
  }

  .protective-padding {
    padding-bottom: 5%;
  }
}

@media (width <= 85.375em) {
  .footer {
    height: 11rem;
  }

  .copyright {
    margin-bottom: 11rem;
  }

  .page-two .text {
    width: 90%;
  }

  .page-three .logo-container {
    height: 28rem;
  }

  .page-three .logo-text {
    font-size: 8.2rem;
  }

  .page-three .page-text {
    width: 85%;
    padding-bottom: 2%;
  }

  .page-two .text h3, .page-three .text-container h3 {
    font-size: 2.8rem;
  }
}

@media (width <= 80em) {
  body {
    font-size: 1.8rem;
  }

  .logo-stripe {
    height: 4.8rem;
  }

  .navigation-img {
    width: 22rem;
  }

  .form ::placeholder {
    font-size: 1.8rem;
  }

  .page-one .big-ideas {
    gap: 12%;
  }

  .page-one .big-ideas p {
    font-size: 6.4rem;
  }

  .page-one .logo-container p {
    font-size: 2.4rem;
  }

  .page-one .statement {
    font-size: 2.8rem;
  }

  .page-one .list, .page-one .end-statement {
    font-size: 2.1rem;
  }

  .page-two .title {
    font-size: 5.2rem !important;
  }

  .page-two .blue-logo {
    width: 21rem;
  }

  .page-two .text h3, .page-three .text-container h3 {
    font-size: 2.1rem !important;
  }

  .page-two .text p {
    font-size: 1.8rem;
  }

  .page-three .logo-container {
    height: 25rem;
  }

  .page-three .logo-text {
    font-size: 7.2rem;
  }

  .page-three .page-text {
    width: 80%;
    padding-bottom: 0;
  }

  .page-three .text-container p {
    font-size: 1.8rem;
  }

  .form-text {
    font-size: 1.9rem;
  }

  .protective-padding {
    padding-bottom: 5%;
  }
}

@media (width <= 68em) {
  body {
    line-height: 1.3;
  }

  .navigation {
    height: 15%;
    position: relative;
  }

  .navigation-list {
    z-index: -10;
    background-color: #102640;
    border-top: none;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 1%;
    font-size: 2.1rem;
    position: absolute;
    top: 100%;
  }

  .navigation-img {
    display: none;
  }

  .navigation-img.phone {
    width: 25rem;
    display: inline-block;
  }

  .nav-line {
    display: none;
  }

  .ph-list {
    color: #fff;
    cursor: pointer;
    z-index: 5;
    align-items: center;
    padding: 1rem;
    font-size: 2.4rem;
    display: flex;
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translate(0, -50%);
  }

  .slide-menu {
    opacity: 0;
    background-color: #102640;
    transition: all .3s;
    overflow: hidden;
    transform: translateY(-75%);
  }

  .slide-menu.open {
    opacity: 1;
    z-index: 10;
    transform: translateY(0);
  }

  .footer {
    z-index: 2;
    background-color: #10264099;
    width: 75%;
    position: absolute;
    bottom: 7.5%;
    left: 50%;
    transform: translate(-50%, 7.5%);
  }

  .copyright {
    text-align: center;
    color: #102640;
    background-color: #d9dde4;
    flex-direction: column;
    align-items: center;
    gap: .4rem;
    margin: 0 auto;
    padding: 2% 0;
    display: flex;
    position: relative;
  }

  .form {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
    width: 90%;
    padding-bottom: 10%;
  }

  .form-text {
    background-color: #10264099;
    width: 100%;
  }

  .form-text p {
    text-align: center;
    flex-direction: column;
    grid-column: span 4;
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0;
    font-size: 2.1rem;
    line-height: .9;
    display: flex;
  }

  .btn-subscribe {
    color: #fff;
    background-color: #d21e46;
    border: none;
    grid-column: span 2;
    width: 50%;
    margin: 0 auto;
    padding: .4rem;
    font-size: 1.8rem;
  }

  .form-text hr, .page-one .logo-container {
    display: none;
  }

  .page-one .big-ideas .logo-stripe {
    left: 35%;
  }

  .page-one .details-ideas .paragraph {
    width: 100%;
    line-height: 1.15;
  }

  .page-one .details-ideas {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding-top: 3%;
  }

  .page-one .list {
    grid-template-columns: min-content min-content;
    justify-items: center;
    line-height: 1.11;
    display: grid;
  }

  .page-one .list-item {
    gap: 2%;
  }

  .page-one .list-item:last-child {
    grid-column: span 2;
  }

  .page-one .list span {
    padding-top: .75%;
  }

  .page-one .end-statement {
    text-align: center;
    width: 100%;
  }

  .page-one .statement {
    grid-template-columns: 1fr;
  }

  .page-one .statement p {
    text-align: center;
    margin: 0 auto;
  }

  .page-one .paragraph {
    width: 90% !important;
  }

  .page-one .big-ideas p {
    text-align: center;
  }

  .page-one .statement hr {
    display: none;
  }

  .page-two .text {
    width: 100%;
    padding: 3% 5%;
  }

  .page-two .text h2 {
    flex-direction: column;
    padding-top: 1%;
    padding-bottom: 3%;
  }

  .page-two .text {
    width: 90%;
  }

  .page-two .blue-logo {
    width: 25rem;
  }

  .page-two .part {
    padding-bottom: 5%;
  }

  .page-two .second-part {
    padding-bottom: 3%;
  }

  .page-two .text h3, .page-three .text-container h3 {
    padding-bottom: 1%;
    font-size: 2.4rem !important;
  }

  .page-two .text p {
    font-size: 1.8rem;
  }

  .btn-link-website {
    white-space: nowrap;
    width: 100%;
    margin: 2% auto 5%;
    padding: 1.4rem;
    font-size: 1.6rem;
  }

  .page-three .page-text {
    text-align: center;
    flex-direction: column;
    width: 75%;
  }

  .page-three .part-title {
    display: none;
  }

  .page-three .text-container h3 {
    padding-top: 3%;
  }

  .page-three .text-container h3:last-child, .padding-top {
    padding-top: 0%;
  }

  .page-three .logo-stripe {
    display: none;
  }

  .page-container .copyright-div {
    padding: 5% 10%;
  }
}

@media (width <= 37.5em) {
  body {
    font-size: 1.6rem;
  }

  .logo-stripe {
    height: 4.4rem;
    display: none;
  }

  .navigation {
    height: 12%;
  }

  .navigation-list {
    font-size: 1.6rem;
  }

  .navigation-img.phone {
    width: 16rem;
  }

  .page-one {
    background-image: none;
  }

  .page-one:before {
    content: "";
    background-image: url("phone1.05a3196a.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 8%;
    left: 0;
  }

  .page-one .page-text {
    top: 20%;
    transform: translate(0%, -20%);
  }

  .page-one .big-ideas .logo-stripe {
    height: 20rem;
    display: inline-block;
    top: -62%;
    left: 15%;
  }

  .page-one .big-ideas p {
    font-size: 5.4rem;
  }

  .page-one .statement {
    padding: 6% 0 0;
    font-size: 2.1rem;
  }

  .page-one .list {
    font-size: 1.6rem;
  }

  .page-one .list-item p, .page-one .end-statement {
    font-weight: 500 !important;
  }

  .page-one .list span {
    width: .8rem;
    height: .8rem;
    margin-top: 1%;
  }

  .page-one .end-statement {
    width: 80%;
    margin: 0 auto;
    font-size: 1.6rem;
    line-height: .9;
  }

  .footer {
    width: 95%;
    bottom: 2%;
  }

  .form input {
    padding: .5rem;
    font-size: 1.6rem;
  }

  .form-text p {
    flex-direction: row;
    gap: 2%;
    width: fit-content;
    margin: 0 auto;
    padding: 1rem 0;
    font-size: 1.6rem;
    line-height: .9;
  }

  .btn-subscribe {
    font-size: 1.6rem;
    font-weight: 400 !important;
  }

  .page-two {
    background-image: none;
  }

  .page-two:before {
    content: "";
    z-index: 1;
    background-image: url("phone2.e26adda0.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0;
  }

  .page-two .page-text {
    z-index: 2;
    width: 85%;
    height: 90%;
    position: relative;
  }

  .page-two .title {
    font-size: 3.8rem !important;
  }

  .page-two .blue-logo {
    width: 16rem;
  }

  .page-two .text {
    padding: 3% 2%;
  }

  .page-two .text h3, .page-three .text-container h3 {
    padding-bottom: 1%;
    font-size: 1.8rem !important;
  }

  .page-two .text p {
    font-size: 1.6rem;
  }

  .page-two .text {
    width: 95%;
  }

  .page-two .page-text {
    padding: 0%;
  }

  .page-two .second-part {
    padding-bottom: 1%;
  }

  .btn-link-website {
    white-space: wrap;
    margin: 2% auto 5%;
    padding: .8rem;
    font-size: 1.4rem;
  }

  .page-three {
    overflow: visible;
  }

  .page-three .page-text {
    width: 85%;
    height: 100%;
    top: 10%;
    transform: translate(0%, 10%);
  }

  .page-three .text-container {
    background-color: #d9dde4;
    padding: 6% 0% 1%;
  }

  .page-three .logo-container {
    height: 18rem;
  }

  .page-three .logo-text {
    font-size: 5.2rem;
  }

  .page-three .text-container p {
    font-size: 1.6rem;
  }

  .copyright {
    display: none;
  }

  .copyright-phone {
    flex-direction: column;
    padding-top: 15%;
    display: flex;
  }

  .copyright-phone p {
    font-size: 1.3rem !important;
  }

  .page-container .copyright-div {
    padding: 8% 10% 25%;
  }

  .page-container .copyright-div h2 {
    font-size: 1.8rem;
  }

  .page-container .copyright-div h3, .page-container .copyright-div p {
    text-align: center;
    font-size: 1.6rem;
  }

  .page-terms .copyright-terms {
    text-align: center;
    flex-direction: column;
    gap: .8rem;
    width: 100%;
    padding: 2% .5%;
    font-size: 1.4rem;
  }

  .section-accessibility .accessibility-icon {
    font-size: 3.8rem;
  }

  .section-accessibility .accessibility-slide {
    width: 12rem;
    padding: 3% 5%;
    transform: translateX(-12rem);
  }

  .section-accessibility h3 {
    font-size: 1.6rem;
  }

  .section-accessibility .list {
    gap: .8rem;
    font-size: 1.5rem;
  }
}

@media (width <= 24.375em), (height <= 43.75em) {
  .form {
    gap: .8rem;
    width: 90%;
    padding-bottom: 10%;
  }

  .page-one .big-ideas .logo-stripe {
    height: 18rem;
    top: -62%;
    left: 15%;
  }

  .page-one .big-ideas p {
    font-size: 4.8rem;
  }

  .page-two .text h2 {
    flex-direction: row;
    gap: 2%;
  }

  .page-two .blue-logo {
    width: 14rem;
  }

  .page-two .title {
    font-size: 3.2rem !important;
  }

  .page-three .logo-container {
    height: 16rem;
  }

  .page-three .logo-text {
    font-size: 4.8rem;
  }
}
/*# sourceMappingURL=home.69486a8a.css.map */
